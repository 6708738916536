import React, { useState, useEffect } from 'react';
import WaterFallSection from 'containers/Eval/WaterFallSection';
import NewCases from 'containers/Eval/NewCases';
import './EvalCase.css';
import * as R from 'ramda';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import { operations as evalOperations, selectors as evalSelector } from 'ducks/eval';
import { selectors as dashboardSelector } from 'ducks/dashboard';
import { selectors as widgetsSelectors } from 'ducks/widgets';
import LSAMSNotesWidget from 'components/Widgets/LSAMSNotesWidget';
import { connect } from 'react-redux';
import {
  EVAL_STATUS_CASE_TYPES, OPEN, APPROVED, COMPLETED, REJECTED,
} from '../../constants/eval';
import {
  LSAMS_NOTES,
} from '../../constants/widgets';

const EvalCase = (props) => {
  const {
    fetcEvalScreenInfo, evalScreenInfo, navFromEvalWidget, resolutionId,
    inProgress, openWidgetList,
  } = props;
  const [data, setData] = useState({});
  const [isWaterFallEditable, setIsWaterFallEditable] = useState(true);
  const [isEditable, setIsEditable] = useState(true);

  useEffect(() => {
    fetcEvalScreenInfo();
  }, []);

  useEffect(() => {
    if (!R.isEmpty(evalScreenInfo)) {
      setData(evalScreenInfo);
    }

    const evalStatus = R.pathOr('', ['evalCase', 'status'], evalScreenInfo);
    const caseInfo = R.pathOr('', ['caseInfo'], evalScreenInfo);
    const currentCase = caseInfo && caseInfo.find(item => item.caseId === resolutionId);
    const resolutionStatus = R.pathOr('', ['status'], currentCase);

    if (navFromEvalWidget) {
      if (resolutionStatus !== OPEN) {
        if (evalStatus === APPROVED || evalStatus === COMPLETED || evalStatus === REJECTED) {
          setIsWaterFallEditable(false);
        }
        if (evalStatus === COMPLETED || evalStatus === REJECTED) {
          setIsEditable(false);
        }
      } else if (!EVAL_STATUS_CASE_TYPES.includes(evalStatus)) {
        setIsWaterFallEditable(false);
      }
    }
  }, [evalScreenInfo]);

  return (
    R.isEmpty(data) || inProgress
      ? <Loader message="Please wait" size={60} />
      : (
        <div styleName="container">
          {R.contains(LSAMS_NOTES, openWidgetList) && <LSAMSNotesWidget />}
          <div style={{ display: R.contains(LSAMS_NOTES, openWidgetList) ? 'none' : '' }}>
            <WaterFallSection
              evalScreenData={data}
              isEditable={isEditable}
              isWaterFallEditable={isWaterFallEditable}
            />
            <NewCases evalScreenData={data} isEditable={isWaterFallEditable} />
          </div>
        </div>
      )
  );
};

EvalCase.defaultProps = {
  navFromEvalWidget: false,
  openWidgetList: [],
};

EvalCase.propTypes = {
  evalScreenInfo: PropTypes.shape().isRequired,
  fetcEvalScreenInfo: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
  navFromEvalWidget: PropTypes.bool,
  openWidgetList: PropTypes.arrayOf(PropTypes.string),
  resolutionId: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  evalScreenInfo: evalSelector.getEvalScreenData(state),
  inProgress: evalSelector.inProgress(state),
  resolutionId: dashboardSelector.selectedResolutionId(state),
  openWidgetList: widgetsSelectors.getOpenWidgetList(state),
});

const mapDispatchToProps = dispatch => ({
  fetcEvalScreenInfo: evalOperations.fetcEvalScreenInfoOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EvalCase);
